import React from 'react'
import teamImage from '../images/asdroTeam.jpg'

export default function AsdroDescription() {
  return (
    <section id='sec_ueber_uns' className="sec_ueber_uns">
        <img src={teamImage} alt="ASDRO Team" />
        <div className="ueberUns_container">
            <h2>Wir sind ASDRO</h2>
            <span>
                Die ASDRO GmbH ist ein junges und dynamisches Unternehmen aus dem Ruhrgebiet. 2019 als Spin-off der Universität Münster gegründet arbeiten mittlerweile über 15 ASDROnauten an der drohnengestützten Untergrund- und Oberflächenvermessung.Die ASDRO GmbH verwendet dabei modernste Drohnen- und Sensortechnik um zum einen digitale Abbilder von Oberflächen zu gestalten und zum anderen einen Blick unter die Erde zu werfen. Hier können Kampfmittel, Leitungen, Rohre und archäologische Strukturen bis in einer Tiefe von 5m aufgespürt werden.<br/>Durch eine Kombination von autonomer Datenaufnahme und computergestützter Auswertungsalgorithmen können zukunftsweisende und effiziente Komplettlösung im Bereich der Oberflächen- und Untergrundvermessung angeboten werden. Der Anspruch der ASDRO GmbH ist es stets einen Schritt voraus zu sein um die Technologien und Lösungen von morgen zu entwickeln. Dazu können bereits jetzt Lösungen angeboten werden, die der Maßstab für fortschrittliche und präzise Arbeit darstellt.
            </span>
        </div>

    </section>
  )
}
