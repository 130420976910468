import React from 'react'


export default function AsdroHeader(props) {
  return (
    <div>
        <header>
        <div className="header_text">
            <nav>
                <ul>
                    <li><a href='#header'>Über mich</a></li>
                    <li><a href='#sec_ueber_uns'>Über uns</a></li>
                    <li><a href='#sec_dienstleistung'>Dienstleistung</a></li>
                    <li><a href='#sec_moreInformation'>Mehr Informationen</a></li>
                </ul>
            </nav>
            <h1>{props.vorname} {props.nachname}</h1>
            <h2 className="highlited">{props.position}</h2>
            <p className="header_subheading">{props.description}</p>
        </div>
        <div className="profile_image">
            <img src={props.profileImageLink} alt='Profile' />
        </div>
    </header>
    </div>
  )
}
