import React from 'react'

export default function AsdroService() {
  return (
    <section id='sec_dienstleistung'>
        <h2>Dienst&shy;leistungen</h2>
        <div className="flex-container">
            <div className="card">
                <h3>Untergrund&shy;sondierung</h3>
                <p>ASDRO's Drohnen-Geomagnetik spürt leitfähige Materialien und stromführende Leitungen im Boden auf, indem sie Anomalien im Magnetfeld misst. Die smarte Drohne, ausgerüstet mit einem Magnetometer, schwebt autonom über vorher geplanten Arealen. Mithilfe dieser innovativen Technologie werden Größe und Tiefe von Objekten im Untergrund, wie Kampfmittel, Leitungen, Pipelines aber auch archäologische Funde präzise aufgespürt. So lassen sich sogar Munitionsreste und Blindgänger bis zu 5 Meter tief zu orten.</p>
                <a href="https://www.asdro.de">Weitere Infos</a>
            </div>
            <div className="card">
                <h3>Vermessung</h3>
                <p>Drohnen-Photogrammetrie revolutioniert die Datenerfassung. Autonome Drohnen mit speziellen Kameras erfassen aus verschiedenen Blickwinkeln Aufnahmen des Untersuchungsgebiets. Die resultierenden georeferenzierten 3D-Modelle bieten präzise Informationen zu Oberfläche, Volumen und Beschaffenheit. Ideal für digitale Inspektionen und Planungsprozesse, übertrifft diese Methode herkömmliche Satellitenbilder in Aktualität und Auflösung. Mit einer Lagegenauigkeit von 1-2 cm und Höhengenauigkeit von 2-3 cm liefert sie exakte Ergebnisse.</p>
                <a href="https://www.asdro.de/pages/services/">Weitere Infos</a>
            </div>
            <div className="card">
                <h3>Consulting</h3>
                <p>Unsere Experten bieten umfassende Beratungsdienstleistungen zur Sicherstellung der Kampfmittelfreiheit auf Ihrem Projektgebiet. Wir unterstützen Sie dabei, der Einhaltung der gesetzlichen Rahmenbedingungen und dabei potenzielle Risiken frühzeitig zu identifizieren sowie präventive Maßnahmen zu ergreifen, um eine sichere Umgebung zu gewährleisten. Verlassen Sie sich auf unsere Erfahrung, um Ihr Vorhaben frei von Kampfmitteln zu gestalten und den reibungslosen Fortschritt Ihrer Projekte sicherzustellen.</p>
                <a href="www.asdro.de">Weitere Infos</a>
            </div>
        </div>
    </section>
  )
}
