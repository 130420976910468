import React from 'react'
import iconLinkedIn from '../images/LI-In-Bug_white.png'
import iconAsdro from '../images/ASDRO_icon.png'
import iconAdress from '../images/adresse_icon3.png'

export default function AsdroMoreInformation(props) {
  return (
    <footer id="sec_moreInformation">
        <h2>Mehr Informationen</h2>
        <div className="grid-container">
            <div id="item-1" className="grid-item">
                <a href={props.linkedIn}>
                    <img src= {iconLinkedIn} alt="LinkedIn"/>
                </a>
            </div>
            <div id="item-2" className="grid-item">
                <a href={`mailto:${props.email}`}>
                    <h2>E-Mail</h2>
                    <h3>{props.email}</h3>
                </a>
            </div>
            <div id="item-3" className="grid-item">
                <img src={iconAdress} alt="adresse"/>
                <h3>Karternberger Str. 107 <br /> 45732 Essen</h3>
            </div>
            <div id="item-4" className="grid-item">
                <a href={`tel:${props.telefon}`}>
                    <h2>Telefon</h2>
                    <h3>{props.telefon}</h3>
                </a>
            </div>
            <div id="item-5" className="grid-item">
                <a href='https://www.asdro.de'>
                    <img src={iconAsdro} alt="ASDRO" />
                </a>
            </div>
        </div>
        <ul>
            <li><a href="https://www.asdro.de/pages/imprint/">Impressum</a></li>
            <li><a href="https://www.asdro.de/pages/privacy/">Datenschutzerklärung</a></li>
        </ul>
    </footer>
  )
}
