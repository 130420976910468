import {BrowserRouter, Routes , Route } from "react-router-dom" 
import AsdroDescription from './components/AsdroDescription/AsdroDescription';
import AsdroHeader from './components/AsdroHeader/AsdroHeader';
import AsdroMoreInformation from './components/AsdroMoreInformation/AsdroMoreInformation';
import AsdroService from './components/AsdroService/AsdroService';
import './styles/Theme/darkTheme.css';

import { useEffect, useState } from "react";

function createBusinessCard(user){
  try{
    return(
      <div className="App">
        <AsdroHeader 
          vorname = {user.data.vorname}
          nachname = {user.data.nachname}
          position = {user.data.position}
          description = {user.data.description}
          profileImageLink = {user.data.profileImageLink}
        />
        <AsdroDescription />
        <AsdroService />
        <AsdroMoreInformation 
          email = {user.data.email}
          telefon = {user.data.telefon}
          linkedIn = {user.data.linkedIn}
        />
      </div>
    )
  }catch(e){
    //console.log(e)
  }
  
}

// Empty Card
function notFound(){
  return(
    <div>
      <AsdroHeader 
          vorname = "ASDRO"
          nachname = ""
          position = "Untergund- und Oberflächenvermessung"
          description = "Beschreibung"
          profileImageLink = "./../images/profiles/profile_asdro.png"
        />
      <AsdroDescription />
      <AsdroService />
      <AsdroMoreInformation 
          email = "info@asdro.de"
          telefon = "+49 201 72028417"
          linkedIn = "https://www.linkedin.com/company/asdro/"
        />
      </div>
  )
}

function App() {
 // const [userData, setUserdata] = useState(jsonTest)
  const [userData, setUserdata] = useState(null)
  useEffect(() => {
    fetch('./data/asdronauten.json', {
      headers: {'Content-Type': 'application/json','Accept': 'application/json'}
    })
    .then((response) => response.json())
    .then((data) => setUserdata(data))
    .catch((error) => {
      console.error(error)
    })
  }, [])
/* Alter Return 
<Route path='/bastianwingerath' element={createBusinessCard(userData ? userData.find((asdronaut) => asdronaut.asdronaut === "bastianwingerath") : "")} />
        <Route path='/julianbeautemps' element={createBusinessCard(userData ? userData.find((asdronaut) => asdronaut.asdronaut === "julianbeautemps"): "" )} />
        */

  return (
    <BrowserRouter>
      <Routes>
        {userData ? userData.map((asdronaut) => {
          return (
          <Route key={asdronaut.asdronaut} path={`/${asdronaut.asdronaut}`} element={createBusinessCard(asdronaut)} />
          )
        }) : ""}        
        <Route path="/*" element={notFound()} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
